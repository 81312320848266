import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterContentInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-ooc-footer',
  templateUrl: './ooc-footer.component.html',
  styleUrls: ['./ooc-footer.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class OocFooterComponent implements OnInit, AfterContentInit {

  constructor() { }

  ngOnInit(): void {

  }

  ngAfterContentInit() {

    const footer = document.getElementById('ft');

    // if (environment.isDeployedAzureCloud) {
    //   footer.outerHTML = `
    // <a title="DFPS Home" href="https://test.dfps.texas.gov/default.asp" lang="en">DFPS home</a> | 
    // <a title="website policies" href="https://test.dfps.texas.gov/policies/" lang="en">Website policies</a> | 
    // <a title="Texas online" href="http://www.texas.gov" lang="en">Texas online</a> | 
    // <a title="statewide search" href="https://www.tsl.texas.gov/trail/index.html" lang="en">Statewide search</a> | 
    // <a title="contact us" href="https://test.dfps.texas.gov/Contact_Us/" lang="en">Contact us</a> | 
    // <a title="get PDF reader" href="https://test.dfps.texas.gov/policies/viewing.asp" lang="en">Download help</a> | 
    // <a title="top of page" href="#top" lang="en">Top of page</a>
    // `;
    //} else {
    // let headers = new HttpHeaders()
    // headers = headers.set('Access-Control-Allow-Origin', '*');
    // console.log(headers)
    $.get(`${environment.oOCstaticContentUrl}/specialty/footer.asp`)
      //this.client.get(`https://testcontent-hke3arh7h6bcfqcp.z01.azurefd.net/Adoption_and_Foster_Care/common/specialty/footer.asp`, { headers: headers }).toPromise()
      .then(function (data) {
        footer.outerHTML = data;
      });
    // }
  }

}
