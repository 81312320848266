<ng-container *transloco="let cs">
    <div class="row">
        <h1 class="col-sm-12">{{cs('childSearchHeading')}}</h1>
        <div class="col-sm-12">
            <hr aria-hidden="true" />
        </div>
    </div>
    <div class="row pl-3 pt-3" *transloco="let hc; read:'childSearchHeadingContent'">
        <p class="col-sm-12 boldBody">{{hc('content1')}}</p>
        <p class="col-sm-12 boldBody" [innerHTML]="hc('content2')"></p>
    </div>
    <div #errors>
        <dfps-form-validation [shouldCheckDirty]="true" [validationErrors]="validationErrors"
            [validationErrorsLength]="validationErrorsLength">
        </dfps-form-validation>
    </div>

    <form [formGroup]="childSearchForm" (ngSubmit)="onChildSearch()" (reset)="onReset()">
        <div class="row pl-3 pt-1">
            <h2 class="col-sm-12">{{cs('childCharsHeading')}}</h2>
            <div class="col-sm-12">
                <hr aria-hidden="true" />
            </div>
        </div>
        <div class="row pl-4">
            <div class="d-flex col-lg-6 pt-3">
                <label for="firstName" class="col-sm-3 col-form-label col-form-label-sm">{{cs('firstName')}}:</label>
                <div class="col-sm-9 col-md-9 col-lg-7 col-xl-4 pl-0">
                    <dfps-input _id="firstName" ariaLabel="{{cs('firstName')}}" formControlName="firstName"
                        maxlength="30">
                    </dfps-input>
                </div>
            </div>
            <div class="d-flex col-lg-6 pt-3">
                <label for="tareId" class="col-sm-3 col-form-label col-form-label-sm notranslate">{{cs('tareId')}}:
                </label>
                <div class="col-sm-9 col-md-9 col-lg-7 col-xl-4 pl-0">
                    <dfps-input _id="tareId" ariaLabel="{{cs('tareId')}}" formControlName="tareId" maxlength="30">
                    </dfps-input>
                </div>
            </div>
        </div>
        <div class="row pl-4">
            <div class="d-flex col-lg-6 pt-3">
                <label for="groupId" class="col-sm-3 col-form-label col-form-label-sm">{{cs('groupType')}}:</label>
                <div class="col-sm-9 col-md-9 col-lg-7 col-xl-4 pl-0">
                    <dfps-select _id="groupId" ariaLabel="{{cs('groupType')}}" formControlName="groupId"
                        [dropDownValues]="childSearchLookup$ ? childSearchLookup$['groupType']:[]">
                    </dfps-select>
                </div>
            </div>
            <div class="d-flex col-lg-6 pt-3">
                <label for="age" class="col-sm-3 col-form-label col-form-label-sm">{{cs('age')}}: </label>
                <div class="col-sm-9 col-md-9 col-lg-7 col-xl-4 pl-0">
                    <dfps-select _id="age" ariaLabel="{{cs('age')}}" formControlName="age"
                        [dropDownValues]="childSearchLookup$ ? childSearchLookup$['age']:[]">
                    </dfps-select>
                </div>
            </div>
        </div>
        <div class="row pl-4">
            <div class="d-flex col-lg-6 pt-3">
                <label for="gender" class="col-sm-3 col-form-label col-form-label-sm">{{cs('gender')}}:</label>
                <div class="col-sm-9 col-md-9 col-lg-7 col-xl-4 pl-0">
                    <dfps-select _id="gender" ariaLabel="{{cs('gender')}}" formControlName="gender"
                        [dropDownValues]="childSearchLookup$ ? childSearchLookup$['gender']:[]">
                    </dfps-select>
                </div>
            </div>
            <div class="d-flex col-lg-6 pt-3">
                <label for="area" class="col-sm-3 col-form-label col-form-label-sm">{{cs('area')}}: </label>
                <div class="col-sm-9 col-md-9 col-lg-7 col-xl-4 pl-0">
                    <dfps-select _id="area" ariaLabel="{{cs('area')}}" formControlName="area"
                        [dropDownValues]="childSearchLookup$ ? childSearchLookup$['regions']:[]">
                    </dfps-select>
                </div>
            </div>
        </div>
        <div class="row pl-4">
            <div class="d-flex col-lg-6 pt-3">
                <label for="ethnicity" class="col-sm-3 col-form-label col-form-label-sm">{{cs('ethnicity')}}:</label>
                <div class="col-sm-9 col-md-9 col-lg-7 col-xl-4 pl-0">
                    <dfps-select _id="ethnicity" ariaLabel="{{cs('ethnicity')}}" formControlName="ethnicity"
                        [dropDownValues]="childSearchLookup$ ? childSearchLookup$['ethnicity']:[]">
                    </dfps-select>
                </div>
            </div>
        </div>
        <div class="row pl-4">
            <div class="d-flex col-sm-12 pt-2">
                <dfps-multi-select id="race" [multiSelectValues]="childSearchLookup$ ? childSearchLookup$['race']:[]"
                    [preSelectedValues]="preSelectedRaceValues" formControlName="race" legendTitle="{{cs('race')}}"
                    inputName="race" ariaLabel="{{cs('race')}}" (selectedValues)="selectedRaceValues($event)">
                </dfps-multi-select>
            </div>
        </div>

        <div class="row pl-3 pt-3">
            <h2 class="col-sm-12">{{cs('advancedSearchHeading')}}</h2>
            <div class="col-sm-12">
                <hr aria-hidden="true" />
            </div>
        </div>
        <div class="row pt-3 pl-4" *transloco="let ads; read:'advancedSearchHeadingContent'">
            <p class="col-sm-12 boldBody">{{ads('content1')}} </p>
            <p class="col-sm-12 boldBody" [innerHTML]="ads('content2')"> </p>
        </div>
        <div class="row pl-4">
            <div class="d-flex col-lg-6 pt-3">
                <label for="behavioral" class="col-sm-3 col-form-label col-form-label-sm">{{cs('behavioral')}}:</label>
                <div class="col-sm-9 col-md-9 col-lg-7 col-xl-4 pl-0">
                    <dfps-select _id="behavioral" ariaLabel="{{cs('behavioral')}}" formControlName="behavioral"
                        [dropDownValues]="childSearchLookup$ ? childSearchLookup$['behavioural']:[]">
                    </dfps-select>
                </div>
            </div>
            <div class="d-flex col-lg-6 pt-3">
                <label for="emotional" class="col-sm-3 col-form-label col-form-label-sm">{{cs('emotional')}}:
                </label>
                <div class="col-sm-9 col-md-9 col-lg-7 col-xl-4 pl-0">
                    <dfps-select _id="emotional" ariaLabel="{{cs('emotional')}}" formControlName="emotional"
                        [dropDownValues]="childSearchLookup$ ? childSearchLookup$['emotional']:[]">
                    </dfps-select>
                </div>
            </div>
        </div>
        <div class="row pl-4">
            <div class="d-flex col-lg-6 pt-3">
                <label for="physical" class="col-sm-3 col-form-label col-form-label-sm">{{cs('physical')}}:</label>
                <div class="col-sm-9 col-md-9 col-lg-7 col-xl-4 pl-0">
                    <dfps-select _id="physical" ariaLabel="{{cs('physical')}}" formControlName="physical"
                        [dropDownValues]="childSearchLookup$ ? childSearchLookup$['physical']:[]">
                    </dfps-select>
                </div>
            </div>
            <div class="d-flex col-lg-6 pt-3">
                <label for="medical" class="col-sm-3 col-form-label col-form-label-sm">{{cs('medical')}}:
                </label>
                <div class="col-sm-9 col-md-9 col-lg-7 col-xl-4 pl-0">
                    <dfps-select _id="medical" ariaLabel="{{cs('medical')}}" formControlName="medical"
                        [dropDownValues]="childSearchLookup$ ? childSearchLookup$['medical']:[]">
                    </dfps-select>
                </div>
            </div>
        </div>
        <div class="row pl-4">
            <div class="d-flex col-lg-6 pt-3">
                <label for="learning" class="col-sm-3 col-form-label col-form-label-sm">{{cs('learning')}}:</label>
                <div class="col-sm-9 col-md-9 col-lg-7 col-xl-4 pl-0">
                    <dfps-select _id="learning" ariaLabel="{{cs('learning')}}" formControlName="learning"
                        [dropDownValues]="childSearchLookup$ ? childSearchLookup$['learning']:[]">
                    </dfps-select>
                </div>
            </div>
            <div class="d-flex col-lg-6 pt-3">
                <label for="developmental" class="col-sm-3 col-form-label col-form-label-sm">{{cs('developmental')}}:
                </label>
                <div class="col-sm-9 col-md-9 col-lg-7 col-xl-4 pl-0">
                    <dfps-select _id="developmental" ariaLabel="{{cs('developmental')}}" formControlName="developmental"
                        [dropDownValues]="childSearchLookup$ ? childSearchLookup$['developmental']:[]">
                    </dfps-select>
                </div>
            </div>
        </div>
        <div class="row pl-4">
            <div class="d-flex col-lg-6 pt-3">
                <label for="risk" class="col-sm-3 col-form-label col-form-label-sm">{{cs('risk')}}:</label>
                <div class="col-sm-9 col-md-9 col-lg-7 col-xl-4 pl-0">
                    <dfps-select _id="risk" ariaLabel="{{cs('risk')}}" formControlName="risk"
                        [dropDownValues]="childSearchLookup$ ? childSearchLookup$['riskFactors']:[]">
                    </dfps-select>
                </div>
            </div>
        </div>

        <div class="row pl-4">
            <div class="col-sm-3 col-lg-3 col-xl-4 pt-2">
                <button type="reset" class="btn secondaryButton" id="reset">{{cs('reset')}}</button>
            </div>
            <div class="d-flex col-sm-6 col-lg-6 col-xl-6 justify-content-center">
                <p-progressSpinner *ngIf="requestProcessing"></p-progressSpinner>
            </div>
            <div class="d-flex col-sm-3 col-lg-3 col-xl-2 pt-2 justify-content-end">
                <dfps-button type="submit" size="small" label="{{cs('search')}}" _id="childSearch"
                    [disabled]="requestProcessing"></dfps-button>
            </div>
        </div>
    </form>

    <div class="row rowPadding" *ngIf="displaySearchResultsTable">
        <div class="col-sm-12 pt-3">
            <h2>{{cs('searchResults')}}</h2>
        </div>
    </div>
    <div class="row leftPadSecondLevel rowPadding" [hidden]="!displaySearchResultsTable">
        <div class="col-sm-12">
            <div class="card">
                <p-dataView #dv appAccessibilityPDataViewTare [value]="childrenSearchResults?.childDtos"
                    [paginator]="true" [rows]="DEFAULT_PAGE_SIZE" [rowsPerPageOptions]="[10,20,40,60]"
                    [totalRecords]="totalRecords" layout="grid" (onLazyLoad)="loadDataLazy($event, false)"
                    [lazy]="true">
                    <!-- <ng-template pTemplate="header">
                        <div class="row">
                            <div class="col-sm-6 d-flex">
                                <p-dropdown [options]="sortOptions" placeholder="{{cs('sortBy')}}{{cs('name')}}"
                                    (onChange)="onSortChange($event)" styleClass="p-mb-2 p-mb-md-0"></p-dropdown>
                            </div>
                            <div class="col-sm-6 col-md-6 d-flex justify-content-end">
                                <input type="search" pInputText placeholder="{{cs('searchBy')}}{{cs('name')}}"
                                    [attr.aria-label]="cs('searchBy') + cs('name')"
                                    (input)="dv.filter($event.target.value)">
                            </div>
                        </div>
                    </ng-template> -->
                    <ng-template let-childrenSearchResult pTemplate="gridItem">
                        <div class="col-sm-12 col-md-3 d-inline-block">
                            <div class="product-grid-item card">
                                <div class="product-grid-item-content">
                                    <img class="img-fluid searchResultsImageSize"
                                        *ngIf="childrenSearchResult.primaryMedia && childrenSearchResult.primaryMedia!=null"
                                        [src]="childrenSearchResult.primaryMedia"
                                        (click)="navigateToChildSiblingProfile(childrenSearchResult.hasSiblings)"
                                        [alt]="cs('searchResults') + ': ' + cs('searchResults')+ childrenSearchResult.firstName +' ' + cs('tareId') + ' ' + childrenSearchResult.tareId">
                                    <div class="product-description">
                                        <a [routerLink]="childrenSearchResult.hasSiblings ? ['/child/childSearch/siblingProfile'] : ['/child/childSearch/childProfile']"
                                            [queryParams]="{id:childrenSearchResult?.tareId }"
                                            [attr.aria-label]="cs('select') + childrenSearchResult.firstName + cs('linkwith') + cs('tareId') + childrenSearchResult.tareId + cs('toNavTo') + cs('profilePage')">{{childrenSearchResult.firstName}}</a>

                                        <div *ngIf="isUserLoggedIn">{{cs('match')}} % :
                                            {{childrenSearchResult.matchPercent}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="empty">
                        <div class="col-sm-12"> {{cs('noRecordsFound')}} </div>
                    </ng-template>

                    <ng-template pTemplate="paginatorleft" let-state>
                        {{cs('showing',{firstRecord:state.totalRecords < 1 ? 0 : state.first + 1})}}
                            {{cs('of',{pageRecords:state.totalRecords> 0 ? ((state.rows+ state.first) <=
                                state.totalRecords ? (state.rows + state.first) : state.totalRecords) : 0})}}
                                {{cs('entries',{totalRecords:state.totalRecords ? state.totalRecords : 0})}}
                                </ng-template>

                                <ng-template let-item pTemplate="paginatordropdownitem">
                                    {{cs('entriesPerPage',{noOfEntries:item.value})}}
                                </ng-template>
                </p-dataView>
            </div>
        </div>
    </div>
</ng-container>