import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DfpsFormValidationDirective, DirtyCheck, FormValidationError, SET } from 'dfps-web-lib';
import { FamilyProfileService } from '../../services/family.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { FamilyProfile } from '../../model/family';
import { DataShareService } from '@shared/services/data-share.service';
import { AppUtilService } from '@shared/services/app-util.service';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-adoption-interests',
  templateUrl: './adoption-interests.component.html',
  styleUrls: [
    "../../../../../.././node_modules/bootstrap/dist/css/bootstrap.min.css",
    "../../../../../.././node_modules/font-awesome/css/font-awesome.css",
    "../../../../../.././node_modules/primeng/resources/themes/saga-blue/theme.css",
    "../../../../../.././node_modules/primeng/resources/primeng.min.css",
    "../../../../../.././node_modules/primeicons/primeicons.css",
    '../../../../../.././node_modules/dfps-web-lib/resources/styles/styles.css',
    './adoption-interests.component.css'
  ],
  encapsulation: ViewEncapsulation.Emulated
})
export class AdoptionInterestsComponent extends DfpsFormValidationDirective implements OnInit {

  @ViewChild('errors', { static: false }) errorElement: ElementRef;

  validationErrors: FormValidationError[] = [];
  validationErrorsLength = 0;
  adoptionInterestsForm: FormGroup;
  requestProcessing: Boolean;
  displayAdoptionInterestsDetails: FamilyProfile;
  familyProfileSuccessMsg: string;
  adoptionInterestsLookup$: Observable<any>;
  renderFamilyProfile: boolean;

  constructor(
    private familyProfileService: FamilyProfileService,
    private formBuilder: FormBuilder,
    private toasterService: ToastrService,
    private appUtilService: AppUtilService,
    private shareService: DataShareService,
    private datePipe: DatePipe,
    public store: Store<{ dirtyCheck: DirtyCheck }>) {
    super(store);
  }

  ngOnInit(): void {
    this.renderFamilyProfile = false;
    this.appUtilService.isGoogleTranslateSpanish$$.subscribe(value => {
      if (value) {
        this.familyProfileService.getAdoptionInterestsLookupsES().then(data => {
          this.adoptionInterestsLookup$ = data;
        });
      } else {
        this.familyProfileService.getAdoptionInterestsLookups().then(data => {
          this.familyProfileService.adoptionInterestsLookup = data;
          this.adoptionInterestsLookup$ = data;
        });
      }
    });
    this.createForm();
    this.patchAdoptionInterestsData();
    this.store.dispatch(SET({ dirtyCheck: { isDirty: false } }));
  }

  createForm() {
    this.adoptionInterestsForm = this.formBuilder.group({
      familyId: [],
      maxChildrenAdopt: ['', [Validators.required]],
      maxAgeChildrenToAdopt: ['', [Validators.required]],
      childAdoptGenderPreference: ['', [Validators.required]],
      childAdoptRacePreference: ['', [Validators.required]],
      childAdoptEthnicityPreference: ['', [Validators.required]],
      searchRank: []
    });
  }

  patchAdoptionInterestsData() {
    this.familyProfileService.getFamilyProfileByEmail().subscribe((res) => {
      if (res && (res != null)) {
        this.displayAdoptionInterestsDetails = res;
        const detailsResponse: any = this.displayAdoptionInterestsDetails;
        const adoptionInterestResponse: any = detailsResponse.adoptionInterestDTO;
        this.adoptionInterestsForm.patchValue({
          familyId: detailsResponse.familyId,
          maxChildrenAdopt: adoptionInterestResponse.maxChildrenAdopt,
          maxAgeChildrenToAdopt: adoptionInterestResponse.maxAgeChildrenToAdopt,
          childAdoptGenderPreference: adoptionInterestResponse.childAdoptGenderPreference,
          childAdoptRacePreference: adoptionInterestResponse.childAdoptRacePreference,
          childAdoptEthnicityPreference: adoptionInterestResponse.childAdoptEthnicityPreference,
          searchRank: adoptionInterestResponse.searchRank && adoptionInterestResponse.searchRank.length > 0
            ? this.addDecodeToCharCodeList(this.adoptionInterestsLookup$['rankDefaultList'], adoptionInterestResponse.searchRank) : this.adoptionInterestsLookup$['rankDefaultList']
        });
        this.renderFamilyProfile = true;
      }
    },
      error => {
        this.adoptionInterestsForm.get('searchRank').setValue(this.adoptionInterestsLookup$['rankDefaultList']);
        this.toasterService.error(error, 'getAdoptionInterests Error');
        this.renderFamilyProfile = true;
      });
  }

  addDecodeToCharCodeList(charsDefaultList, searchRank) {
    const defaultList = JSON.parse(JSON.stringify(charsDefaultList));
    let finalList = [];
    if (defaultList !== null && typeof charsDefaultList !== 'undefined') {
      searchRank.map((rank) => {
        defaultList.forEach(obj => {
          if (obj.code === rank.cdMeasure)
            finalList.push({ code: obj.code, decode: obj.decode });
        });
      });
    }
    return finalList;
  }

  onTrimSpace(formControl) {
    this.appUtilService.onTrimSpace(this.adoptionInterestsForm, formControl);
  }

  onSaveAdoptionInterestsForm() {
    if (this.validateFormGroup(this.adoptionInterestsForm)) {
      this.requestProcessing = true;
      let searchRank = [];
      this.adoptionInterestsForm.value.searchRank.forEach((obj, index) => {
        searchRank.push({ cdMeasure: obj.code, rank: index });
      });
      this.adoptionInterestsForm.get('searchRank').setValue(searchRank);
      this.familyProfileService.saveAdoptionInterests(this.adoptionInterestsForm.value).subscribe((res) => {
        if (res) {
          this.familyProfileSuccessMsg = this.datePipe.transform(Date.now(), 'EEEE, MMMM d, y, h:mm:ss a');
          this.shareService.addDataToMap('familyProfileSuccessMsg', this.familyProfileSuccessMsg);
          this.store.dispatch(SET({ dirtyCheck: { isDirty: false } }));
          this.ngOnInit();
        } else {
          this.adoptionInterestsForm.get('searchRank').setValue(
            this.addDecodeToCharCodeList(this.adoptionInterestsLookup$['rankDefaultList'], this.adoptionInterestsForm.value.searchRank));
        }
        this.requestProcessing = false;
        window.scrollTo(0, 0);
      }, error => {
        this.adoptionInterestsForm.get('searchRank').setValue(
          this.addDecodeToCharCodeList(this.adoptionInterestsLookup$['rankDefaultList'], this.adoptionInterestsForm.value.searchRank));
        this.requestProcessing = false;
        this.toasterService.error(error, 'AdoptionInterests Error');
      });
    } else {
      if (this.errorElement) {
        // for focussing errors.
        setTimeout(() => {
          this.errorElement.nativeElement.querySelector('#dfpsFormValidationDispayDiv').focus();
        }, 1000);
      }
    }
  }

}
