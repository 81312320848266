import { AfterContentInit, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { AppLanguages } from '@shared/enums/app-languages';
import { AppUtilService } from '@shared/services/app-util.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-ooc-header-side-nav',
  templateUrl: './ooc-header-side-nav.component.html',
  styleUrls: ['./ooc-header-side-nav.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class OocHeaderSideNavComponent implements OnInit, AfterContentInit {

  @ViewChild('mainContent') mainContentRef: ElementRef;

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private renderer: Renderer2,
    private authService: MsalService,
    private appUtilService: AppUtilService) { }

  ngOnInit(): void {

  }

  ngAfterContentInit() {
    const href: HTMLElement = document.querySelector('a[href="#pageContent"]');
    this.renderer.setAttribute(href, 'href', '');
    this.renderer.setAttribute(href, 'onclick', 'return false;');
    this.renderer.listen(href, 'click', () => {
      this.mainContentRef.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      this.mainContentRef.nativeElement.focus();
    });

    setTimeout(() => {
      const href1: HTMLElement = document.querySelector('a[href="/Application/TAREPublic/signin"]');
      this.renderer.setAttribute(href1, 'href', '');
      this.renderer.listen(href1, 'click', (event) => {
        event.preventDefault();
        if (this.authService.instance?.getAllAccounts()?.length > 0) {
          alert('User Already Signed In');
          /* localStorage.clear();
          sessionStorage.clear();
          this.authService.logoutRedirect({ postLogoutRedirectUri: environment.redirectUri }); */
        } else {
          let authRequestConfig: RedirectRequest;
          if (this.msalGuardConfig.authRequest) {
            authRequestConfig = { ...this.msalGuardConfig.authRequest } as RedirectRequest
          }
          this.appUtilService.isGoogleTranslateSpanish$$.subscribe(value => {
            if (value) {
              authRequestConfig.extraQueryParameters = { "ui_locales": AppLanguages.Spanish };
            } else {
              authRequestConfig.extraQueryParameters = { "ui_locales": AppLanguages.English };
            }
          });

          this.msalGuardConfig.authRequest ? this.authService.loginRedirect({ ...authRequestConfig } as RedirectRequest) :
            this.authService.loginRedirect();
        }
      });
    }, 1000);

    const header = document.getElementById('header');
    const sidenav = document.getElementById('sidenav');

    // Responsive Template for Header
    // $.get(`${environment.oOCstaticContentUrl}/app/header.asp`).then(function (data) {
    // $.get(`../assets/templates/headertare.asp`).then(function (data) {
    $.get(`${environment.oOCstaticContentUrl}/app/headertare.asp`).then(function (data) {
      data = data.replace("/Application/TARE/Account.aspx/Logon", "/Application/TAREPublic/signin");
      data = data.replace("/Application/TARE/Search.aspx/Children", "/Application/TAREPublic/child/childSearch");
      // if (environment.isDeployedAzureCloud) {
      const imagePath: string = environment.oOCAdoptionFosterCareUrl;
      data = data.replace("/Adoption_and_Foster_Care/images/common/DFPS_logo.png", `${imagePath}/images/common/DFPS_logo.png`);
      data = data.replace("/Adoption_and_Foster_Care/images/common/contact.gif", `${imagePath}/images/common/contact.gif`);
      data = data.replace("/Adoption_and_Foster_Care/images/common/search.gif", `${imagePath}/images/common/search.gif`);
      data = data.replace("/Adoption_and_Foster_Care/images/common/pencil.gif", `${imagePath}/images/common/pencil.gif`);
      // data = data.replaceAll("/Adoption_and_Foster_Care", `${imagePath}`);

      header.outerHTML = data;
    });

    $.get(`${environment.oOCstaticContentUrl}/app/sidenav.asp`, function (data) {
      const imagePath: string = environment.oOCAdoptionFosterCareUrl;
      data = data.replaceAll("/application/TARE/Search.aspx/Children", "/application/TAREPublic/child/childSearch");

      data = data.replaceAll("/Adoption_and_Foster_Care", `${imagePath}`);
      sidenav.outerHTML = data;
      const script = document.createElement('script');
      script.src = `${environment.oOCstaticContentUrl}/app/menu.js`;
      document.getElementsByTagName('head')[0].appendChild(script);

      // Revisit below code once deployed to dev ENV
      setTimeout(() => {
        const image: any[] = [];

        image[1] = `${imagePath}/images/common/banner1.jpg`;
        image[2] = `${imagePath}/images/common/banner2.jpg`;
        image[3] = `${imagePath}/images/common/banner3.jpg`;
        image[4] = `${imagePath}/images/common/banner4.jpg`;
        image[5] = `${imagePath}/images/common/banner5.jpg`;
        image[6] = `${imagePath}/images/common/banner6.jpg`;
        image[7] = `${imagePath}/images/common/banner7.jpg`;
        const random_num: number = (Math.round((Math.random() * 6) + 1));
        document.getElementById('mainnav').classList.add("nav");
        document.getElementById('header_image').insertAdjacentHTML('afterbegin',
          "<a href='/Adoption_and_Foster_Care/default.asp' class='no_link'><img src=\"" + image[random_num] + "\" class='img-fluid' width='550' height='200' alt='Adoption: Why Not You?' border='0'></a>");

      }, 1000);
    });

    /*
    const cross_origin_script_url = `${environment.oOCstaticContentUrl}/app/menu.js`;

    const worker_url = getWorkerURL(cross_origin_script_url);
    const worker = new Worker(worker_url);
    worker.onmessage = (evt) => sidenav.outerHTML = evt.data;
    URL.revokeObjectURL(worker_url);

    // Returns a blob:// URL which points
    // to a javascript file which will call
    // importScripts with the given URL
    function getWorkerURL(url) {
      const content = `importScripts( "${url}" );`;
      return URL.createObjectURL(new Blob([content], { type: "text/javascript" }));
    }
    */

  }

}
