import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataTable, DropDown } from 'dfps-web-lib';
import { HomeService } from '../home.service';
import { environment } from 'environments/environment';
import { DataShareService } from '@shared/services/data-share.service';
import { ToastrService } from 'ngx-toastr';
import { ChildSearchService } from 'app/modules/child/services/child.service';
import { translate } from '@ngneat/transloco';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [
    "../../../../.././node_modules/bootstrap/dist/css/bootstrap.min.css",
    "../../../../.././node_modules/font-awesome/css/font-awesome.css",
    "../../../../.././node_modules/primeng/resources/themes/saga-blue/theme.css",
    "../../../../.././node_modules/primeng/resources/primeng.min.css",
    "../../../../.././node_modules/primeicons/primeicons.css",
    '../../../../.././node_modules/dfps-web-lib/resources/styles/styles.css',
    './dashboard.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class DashboardComponent implements OnInit {

  notificationstRes: any;
  bookmarksRes: any;
  inquiriesRes: any;
  notificationsDataTable: DataTable;
  /* bookmarksDataTable: DataTable;
  inquiriesDataTable: DataTable;
  notificationsTableColumn: any[];
  bookmarksTableColumn: any[];
  inquiriesTableColumn: any[]; */
  renderNotificationsTable: boolean;
  renderBookmarksTable: boolean;
  renderInquiriesTable: boolean;
  DEFAULT_PAGE_SIZE = 10;
  ROW_OPTIONS: DropDown[] = [
    { code: '5', decode: '5' },
    { code: '10', decode: '10' },
    { code: '25', decode: '25' },
    { code: '50', decode: '50' }];
  familyProfileInfo: any;

  notificationTableColumns: { field: string; header: string; filterMatchMode: string; }[];
  bookmarkTableColumns: { field: string; header: string; filterMatchMode: string; }[];
  inquiriesTableColumn: { field: string; header: string; filterMatchMode: string; }[];
  notificationsTableTotalRecords: any;
  bookmarkTableTotalRecords: any;
  inquiriesTableTotalRecords: any;
  notificationTableFilterToggleInd: boolean;
  bookmarkTableFilterToggleInd: boolean;
  inquiriesTableFilterToggleInd: boolean;

  constructor(
    private toasterService: ToastrService,
    private homeService: HomeService,
    private shareService: DataShareService,
    private childService: ChildSearchService) { }

  ngOnInit(): void {
    this.familyProfileInfo = this.shareService.getDataFromMap('familyProfileInfo');
    this.getAllNotifications();
    this.getAllBookmarks();
    this.getAllInquiries();
  }

  getAllNotifications() {
    setTimeout(() => {
      this.notificationTableColumns = [
        { field: 'childName', header: translate('childOrGroup'), filterMatchMode: 'contains' },
        { field: 'notice', header: translate('notice'), filterMatchMode: 'contains' }];
    }, 100);
    this.homeService.getNotificationsRequest(this.familyProfileInfo.familyId).subscribe(
      response => {
        this.notificationstRes = response;
        this.notificationsTableTotalRecords = this.notificationstRes.length;
        this.renderNotificationsTable = true;
      },
      error => {
        this.toasterService.error(error, 'getNotifications Error');
        this.renderNotificationsTable = true;
      });
  }

  getAllBookmarks() {
    setTimeout(() => {
      this.bookmarkTableColumns = [
        { field: 'txtBlobUrl', header: translate('photo'), filterMatchMode: 'contains' },
        { field: 'childName', header: translate('childOrGroup'), filterMatchMode: 'contains' },
        { field: 'dtCreated', header: translate('bookmarkDate'), filterMatchMode: 'contains' }];
    }, 100);
    this.homeService.getBookmarks(this.familyProfileInfo.familyId).subscribe(
      response => {
        this.bookmarksRes = response;
        this.bookmarkTableTotalRecords = this.bookmarksRes.length;
        this.renderBookmarksTable = true;
      },
      error => {
        this.toasterService.error(error, 'getBookmarks Error');
        this.renderBookmarksTable = true;
      });
  }

  getAllInquiries() {
    setTimeout(() => {
      this.inquiriesTableColumn = [
        { field: 'status', header: translate('status'), filterMatchMode: 'contains' },
        { field: 'submitDate', header: translate('submitDate'), filterMatchMode: 'contains' },
        { field: 'childName', header: translate('childOrGroup'), filterMatchMode: 'contains' }];
    }, 100);
    this.homeService.getInquiries(this.familyProfileInfo.familyId).subscribe(
      response => {
        this.inquiriesRes = response;
        this.inquiriesTableTotalRecords = this.inquiriesRes.length;
        this.renderInquiriesTable = true;
      },
      error => {
        this.toasterService.error(error, 'getInquiries Error');
        this.renderInquiriesTable = true;
      });
  }

  deletebookmark(selectedRow) {
    this.childService.addRemoveBookmark(selectedRow.idChild, selectedRow.familyId, false, selectedRow.idSiblingGroup ? true : false).subscribe((res) => {
      this.bookmarksRes = [];
      this.getAllBookmarks();
    },
      error => {
        this.toasterService.error(error, 'addRemoveBookmark Error');
      });
  }

  filterToggleNotificationTable(dt) {
    dt.reset();
    this.notificationTableFilterToggleInd = !this.notificationTableFilterToggleInd;
  }

  filterToggleBookmarkTable(dt) {
    dt.reset();
    this.bookmarkTableFilterToggleInd = !this.bookmarkTableFilterToggleInd;
  }

  filterToggleinquiryTable(dt) {
    dt.reset();
    this.inquiriesTableFilterToggleInd = !this.inquiriesTableFilterToggleInd;
  }

  getTableColumnAriaLabel(colHeader: string) {
    return `${colHeader} ${translate('columnFilter')}`;
  }


  tarePublicHome(): string {
    return `${environment.dfpsDomainUrl}/Application/TARE/Home.aspx/Default`;
  }

  /* initializeBookmarksTable() {
    this.bookmarksTableColumn = [
      { field: 'txtBlobUrl', header: 'Photo', sortable: false, width: 200 },
      {
        field: 'childName', header: 'Child/ Group', sortable: true,
        handleClick: true,
        url: '/child/childProfile',
        urlParams: ['idChild'],
        uniqueLinkKey: 'idChild',
        width: 200
      },
      { field: 'dtCreated', header: 'Bookmark Date', sortable: true, width: 75 }
    ];

    this.bookmarksDataTable = {
      tableBody: [],
      tableColumn: this.bookmarksTableColumn,
      isPaginator: true,
      isSingleSelect: true,
    };
  }

  initializeInquiriesTable() {
    this.inquiriesTableColumn = [
      {
        field: 'status', header: 'Status', sortable: true,
        handleClick: true,
        url: '/home/inquiryDetails',
        urlParams: ['inquiryId'],
        uniqueLinkKey: 'inquiryId',
        width: 75
      },
      { field: 'submitDate', header: 'Submit Date', sortable: true, width: 75 },
      {
        field: 'childName', header: 'Child/ Group', sortable: true,
        handleClick: true,
        url: '/child/childProfile',
        urlParams: ['childId'],
        uniqueLinkKey: 'childId',
        width: 100
      }
    ];

    this.inquiriesDataTable = {
      tableBody: [],
      tableColumn: this.inquiriesTableColumn,
      isPaginator: true,
    };

  } */

  /* handleNameLinkClick(event: any) {
    switch (event.tableColumn.field) {
      case 'childName':
        if (event.tableRow.childId) {
          this.route.navigate([event.link], { queryParams: { id: event.tableRow.childId } });
        } else if (event.tableRow.idChild) {
          this.route.navigate([event.link], { queryParams: { id: event.tableRow.idChild } });
        }
        break;
      case 'status':
        if (event.tableRow.inquiryId) {
          this.route.navigate([event.link], { queryParams: { inquiryId: event.tableRow.inquiryId } });
        }
        break;
    }
  } */
}
