<div class="container-fluid">
    <div class="row">
        <h1 class="col-sm-12">Account Owner's Contact Information</h1>
        <div class="col-sm-12">
            <hr aria-hidden="true" />
        </div>
    </div>
    <div #errors>
        <dfps-form-validation [shouldCheckDirty]="true" [validationErrors]="validationErrors"
            [validationErrorsLength]="validationErrorsLength">
        </dfps-form-validation>
    </div>

    <div class="d-flex col-sm-12 justify-content-center">
        <p-progressSpinner *ngIf="!renderCpaAccount"></p-progressSpinner>
    </div>
    <div class="row">
        <div class="requiredDisplay text-right col-12 pt-3">
            <span class="sr-only">Red asterisk is </span>required field
        </div>
    </div>
    <form [formGroup]="cpaAccountRegisterForm" (ngSubmit)="onSaveCpaAccount()">
        <div class="form-group">
            <div class="row pl-5">
                <div class="d-flex col-md-6 pt-3">
                    <label for="agencyName"
                        class="col-sm-4 col-xl-3 col-form-label col-form-label-sm requiredDisplay">Agency Name:
                    </label>
                    <dfps-input _id="agencyName" ariaLabel="Agency Name" formControlName="agencyName"
                        aria-required="true">
                    </dfps-input>
                </div>
            </div>
            <div class="row pl-5">
                <div class="d-flex col-md-6 pt-3">
                    <label for="firstName"
                        class="col-sm-4 col-xl-3 col-form-label col-form-label-sm requiredDisplay">First Name:</label>
                    <dfps-input class="notranslate" _id="firstName" ariaLabel="First Name" formControlName="firstName"
                        aria-required="true" maxlength="22">
                    </dfps-input>
                </div>
                <div class="d-flex col-md-6 pt-3">
                    <label for="lastName"
                        class="col-sm-4 col-xl-3 col-form-label col-form-label-sm requiredDisplay">Last Name:
                    </label>
                    <dfps-input class="notranslate" _id="lastName" ariaLabel="Last Name" formControlName="lastName"
                        aria-required="true" maxlength="22">
                    </dfps-input>
                </div>
            </div>
            <div class="row pl-5">
                <div class="d-flex col-md-6 pt-3">
                    <label for="primaryPhone"
                        class="col-sm-4 col-xl-3 col-form-label col-form-label-sm requiredDisplay">Your Phone
                        Number:</label>
                    <dfps-input _id="primaryPhone" ariaLabel="Your Phone Number" formControlName="primaryPhone"
                        aria-required="true">
                    </dfps-input>
                </div>
                <div class="d-flex col-md-6 pt-3">
                    <label for="agencyPhoneNumber"
                        class="col-sm-4 col-xl-3 col-form-label col-form-label-sm requiredDisplay">Agency Phone Number:
                    </label>
                    <dfps-input _id="agencyPhoneNumber" ariaLabel="Agency Phone Number"
                        formControlName="agencyPhoneNumber" aria-required="true">
                    </dfps-input>
                </div>
            </div>
            <div class="row pl-5">
                <div class="col-sm-6 pt-3">
                    <div class="row">
                        <p class="boldBody h6"><strong>Agency Mailing Address Information:</strong></p>
                    </div>
                    <div class="row pt-3">
                        <div class="col-sm-12 d-flex">
                            <label for="mailingAddress0"
                                class="col-sm-3 col-form-label col-form-label-sm requiredDisplay">Address 1:</label>
                            <dfps-input _id="mailingAddress0" ariaLabel="Mailing Address 1" aria-required="true"
                                formControlName="mailingAddress0" maxlength="30">
                            </dfps-input>
                        </div>
                    </div>
                    <div class="row pt-3">
                        <div class="col-sm-12 d-flex">
                            <label for="mailingAddress1" class="col-sm-3 col-form-label col-form-label-sm">Address
                                2:</label>
                            <dfps-input _id="mailingAddress1" ariaLabel="Mailing Address 2"
                                formControlName="mailingAddress1" maxlength="30">
                            </dfps-input>
                        </div>
                    </div>
                    <div class="row pt-3">
                        <div class="col-sm-12 d-flex">
                            <label for="mailingCity"
                                class="col-sm-3 col-form-label col-form-label-sm requiredDisplay">City:</label>
                            <dfps-input _id="mailingCity" ariaLabel="Mailing City" formControlName="mailingCity"
                                aria-required="true" maxlength="20">
                            </dfps-input>
                        </div>
                    </div>
                    <div class="row pt-3">
                        <div class="col-sm-12 d-flex">
                            <label for="mailingState"
                                class="col-sm-3 col-form-label col-form-label-sm requiredDisplay">State:</label>
                            <dfps-select _id="mailingState" ariaLabel="Mailing State" formControlName="mailingState"
                                aria-required="true"
                                [dropDownValues]="cpaAccountLookup$ ? cpaAccountLookup$['state']:[]"
                                (change)="changeState()">
                            </dfps-select>
                        </div>
                    </div>
                    <div class="row pt-3">
                        <div class="col-sm-12 d-flex">
                            <label for="mailingCounty"
                                class="col-sm-3 col-form-label col-form-label-sm requiredDisplay">County:</label>
                            <dfps-select _id="mailingCounty" ariaLabel="Mailing County" formControlName="mailingCounty"
                                aria-required="true"
                                [dropDownValues]="cpaAccountLookup$ ? cpaAccountLookup$['counties']:[]"
                                (change)="changeCounty()">
                            </dfps-select>
                        </div>
                    </div>
                    <div class="row pt-3">
                        <div class="col-sm-12 d-flex">
                            <label for="mailingZip"
                                class="col-sm-3 col-form-label col-form-label-sm requiredDisplay">Zip
                                :</label>
                            <dfps-input _id="mailingZip" ariaLabel="Mailing Zip" formControlName="mailingZip"
                                aria-required="true" maxlength="5" (paste)="pasteNumberOnly($event)"
                                (keypress)="validateNumberOnly($event)">
                            </dfps-input>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 pt-3">
                    <div class="row">
                        <p class="boldBody h6"><strong>Agency Physical Address Information:</strong></p>
                        <div class="col-sm-12 pt-2 d-flex">
                            <dfps-checkbox _id="sameMailingAddress" ariaLabel="Same as Mailing Address"
                                label="Same as Mailing Address" inputName="sameMailingAddress" name="sameMailingAddress"
                                [formControl]="cpaAccountRegisterForm.controls['sameMailingAddress']"
                                (click)="sameMailingAddress()">
                            </dfps-checkbox>
                        </div>
                    </div>
                    <div class="row" *ngIf="!cpaAccountRegisterForm.controls['sameMailingAddress'].value">
                        <div class="col-sm-12">
                            <div class="row pt-3">
                                <div class="col-sm-12 d-flex">
                                    <label for="physicalAddress0"
                                        class="col-sm-3 col-form-label col-form-label-sm requiredDisplay">Address
                                        1:</label>
                                    <dfps-input _id="physicalAddress0" ariaLabel="Physical Address 1"
                                        aria-required="true" formControlName="physicalAddress0" maxlength="30">
                                    </dfps-input>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 pt-3 d-flex">
                                    <label for="physicalAddress1"
                                        class="col-sm-3 col-form-label col-form-label-sm">Address
                                        2:</label>
                                    <dfps-input _id="physicalAddress1" ariaLabel="physical Address 2"
                                        formControlName="physicalAddress1" maxlength="30">
                                    </dfps-input>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 pt-3 d-flex">
                                    <label for="physicalCity"
                                        class="col-sm-3 col-form-label col-form-label-sm requiredDisplay">city:</label>
                                    <dfps-input _id="physicalCity" ariaLabel="physical city"
                                        formControlName="physicalCity" aria-required="true" maxlength="20">
                                    </dfps-input>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 pt-3 d-flex">
                                    <label for="physicalState"
                                        class="col-sm-3 col-form-label col-form-label-sm requiredDisplay">
                                        State:</label>
                                    <dfps-select _id="physicalState" ariaLabel="physical State" aria-required="true"
                                        formControlName="physicalState"
                                        [dropDownValues]="cpaAccountLookup$ ? cpaAccountLookup$['state']:[]"
                                        (change)="changeState()">
                                    </dfps-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 pt-3 d-flex">
                                    <label for="physicalCounty"
                                        class="col-sm-3 col-form-label col-form-label-sm requiredDisplay">County:</label>
                                    <dfps-select _id="physicalCounty" ariaLabel="Physical County" aria-required="true"
                                        formControlName="physicalCounty"
                                        [dropDownValues]="cpaAccountLookup$ ? cpaAccountLookup$['counties']:[]"
                                        (change)="changeCounty()">
                                    </dfps-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 pt-3 d-flex">
                                    <label for="physicalZip"
                                        class="col-sm-3 col-form-label col-form-label-sm requiredDisplay">Zip :</label>
                                    <dfps-input _id="physicalZip" ariaLabel="physical Zip" formControlName="physicalZip"
                                        aria-required="true" maxlength="5" (paste)="pasteNumberOnly($event)"
                                        (keypress)="validateNumberOnly($event)">
                                    </dfps-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pl-5">
                <div class="d-flex col-sm-12 pt-3 justify-content-end">
                    <dfps-button type="submit" size="small" label="Save Changes" _id="saveCpaAccount"
                        [disabled]="requestProcessing"></dfps-button>
                </div>
            </div>
        </div>
    </form>
</div>