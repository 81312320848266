import { Component, Inject, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { AuthTareService } from '@shared/services/auth-tare.service';
import { AppUtilService } from '@shared/services/app-util.service';
import { AppLanguages } from '@shared/enums/app-languages';

@Component({
  selector: 'app-logon',
  templateUrl: './logon.component.html',
  styleUrls: ['./logon.component.css']
})
export class LogonComponent implements OnInit {

  subs: Subscription[] = [];
  isUserLoggedIn: boolean;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,

    private authTareService: AuthTareService,
    private authService: MsalService,
    private appUtilService: AppUtilService) {
  }

  ngOnInit(): void {
    this.authTareService.userLoginFlow();
  }

  login() {
    let authRequestConfig: RedirectRequest;
    if (this.msalGuardConfig.authRequest) {
      authRequestConfig = { ...this.msalGuardConfig.authRequest } as RedirectRequest
    }
    this.appUtilService.isGoogleTranslateSpanish$$.subscribe(value => {
      if (value) {
        authRequestConfig.extraQueryParameters = { "ui_locales": AppLanguages.Spanish };
      } else {
        authRequestConfig.extraQueryParameters = { "ui_locales": AppLanguages.English };
      }
    });

    this.msalGuardConfig.authRequest ? this.authService.loginRedirect({ ...authRequestConfig } as RedirectRequest) :
      this.authService.loginRedirect();
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

}
